import * as React from "react";
import logo from "./home_logo.png";

function SvgComponent(props) {
  return (
    <img
      src={logo}
      alt="Logo"
      width={80}
      height={60}
      {...props}
    />
  );
}

export default SvgComponent;
