import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField,Checkbox } from '@mui/material';
import axios from 'axios';
import { CheckBox } from '@mui/icons-material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editedUserInfo, setEditedUserInfo] = useState({});

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = async () => {
        try {
            const response = await axios.get('http://localhost:5000/authenticate'); // Assuming your backend endpoint for getting all users is '/api/users'
            setUsers(response.data);
        } catch (error) {
            toast.error("Error fetching users:", error);
        }
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setEditedUserInfo(user);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleUpdateUser = async () => {
        try {
            await axios.put(`http://localhost:5000/authenticate/${selectedUser._id}`, editedUserInfo); // Assuming your backend endpoint for updating a user is '/api/users/:id'
            setOpenEditDialog(false);
            getAllUsers(); // Fetch updated user list
            toast.success("Successfully Data Saved")
        } catch (error) {
            toast.error("Error updating user:", error)
        }
    };

    const handleChange = (e) => {
        setEditedUserInfo({ ...editedUserInfo, [e.target.name]: e.target.value });
    };
    const handleDeleteUser = async (userId) => {
        try {
            const response = await axios.delete(`http://localhost:5000/authenticate/${userId}`);
            setUsers(users.filter(user => user._id !== userId));
            toast.success("Successfully Deleted ",response.data);
        } catch (error) {
            toast.error("Error deleting user:", error);
        }
    };
    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="user table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Mac Address</TableCell>
                            <TableCell>Version</TableCell>
                            <TableCell>Is Paid</TableCell>
                            <TableCell>App Type</TableCell>
                            <TableCell>App Device ID</TableCell>
                            <TableCell>Device Key</TableCell>
                            <TableCell>Is Trial</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>Pin Code</TableCell>
                            <TableCell>Plan ID</TableCell>
                            <TableCell>Expire Date</TableCell>

                            <TableCell>Action </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => (
                            <TableRow key={user._id}>
                                <TableCell>{user.mac_address}</TableCell>
                                <TableCell>{user.version}</TableCell>
                                <TableCell>{user.is_paid}</TableCell>
                                <TableCell>{user.app_type}</TableCell>
                                <TableCell>{user.app_device_id}</TableCell>
                                <TableCell>{user.device_key}</TableCell>
                                <TableCell>{user.is_trial}</TableCell>
                                <TableCell>{user.price}</TableCell>
                                <TableCell>{user.pin_code}</TableCell>
                                <TableCell>{user.plan_id}</TableCell>
                                <TableCell>{user.expire_date}</TableCell>
                                <TableCell>
                                    <Button onClick={() => handleEditUser(user)} variant="contained" color="primary">Edit</Button>
                                    <Button onClick={() => handleDeleteUser(user._id)} variant="contained" color="secondary">Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Edit User Dialog */}
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog} aria-labelledby="edit-user-dialog-title">
                <DialogTitle id="edit-user-dialog-title">Edit User</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="is_trial"
                        name="is_trial"
                        label="Is Trail"
                        type="text"
                        fullWidth
                        value={editedUserInfo.is_trial}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="is_paid"
                        name="is_paid"
                        label="Is Paid"
                        type="checkbox"
                        fullWidth
                        value={editedUserInfo.is_paid}
                        onChange={handleChange}
                    />
                    <TextField
                        margin="dense"
                        id="expire_date"
                        name="expire_date"
                        label="expire_date"
                        type="date"
                        fullWidth
                        value={editedUserInfo.expire_date}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateUser} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserManagement;
