import React, { useState } from 'react';
import axios from 'axios'; // Import axios library
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TextField, Button, Typography, Container } from '@mui/material';

const Login = () => {
  const [mac_address, setMacAddress] = useState('');
  const [device_key, setDeviceKey] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!mac_address || !device_key) {
      toast.error("Username and password are required.");
      return;
    }
    try {
      const response = await axios.post('http://localhost:5000/authenticate/login', { mac_address, device_key });
      console.log(response.data); 

      localStorage.setItem('userInfo', JSON.stringify(response.data.responseData));
      navigate('/ManagePlaylist');
    } catch (error) {
      toast.error("Error logging in",error);
    }
    setDeviceKey('');
    setMacAddress('');
    setError('');
  };
  const formatMacAddress = (value) => {
    const sanitizedValue = value.replace(/[^A-Fa-f0-9]/g, '');
    const formattedValue = sanitizedValue.match(/.{1,2}/g)?.join(':') || '';
    return formattedValue;
  };

  const handleInputChange = (e) => {
    const formattedValue = formatMacAddress(e.target.value);
    setMacAddress(formattedValue);
  };
  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '13%', marginBottom: '19%' }}>
      <div>
        <Typography component="h1" variant="h5">
          Login to add your playlist
        </Typography>
        {error && <Typography color="error">{error}</Typography>}
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="mac_address"
            label="Mac Address"
            name="macAddress"
            maxlength={23}
            placeholder="e.g. c8:a4:76:30:47:48"
            autoComplete="mac_address"
            autoFocus
            value={mac_address}
            onChange={handleInputChange}

            inputProps={{ maxLength: 23 }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="device_key"
            label="Device Key"
            maxlength="6"
            type="device_key"
            id="device_key"
            placeholder="e.g. 123456"
            autoComplete="device_key"
            value={device_key}
            onChange={(e) => setDeviceKey(e.target.value)}
            inputProps={{ maxLength: 6 }}
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Login
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Login;
