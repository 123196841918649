import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TopNavbar from "./components/Nav/TopNavbar";
import Header from "./components/Sections/Header";
import Footer from "./components/Sections/Footer";

// Screens
import Landing from "./screens/Landing.jsx";

import Services from "./components/Sections/Services";
import Contact from "./components/Sections/Contact";
import Login from "./components/Pages/Login";
import ManagePlaylist from "./components/Pages/ManagePlaylist";

export default function App() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
      <TopNavbar />

      <Routes>
        <Route exact path="/" element={<MainContent />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/ManagePlaylist" element={<ManagePlaylist />} />
      </Routes>
      <Footer />

    </>
  );
}
function MainContent() {
  return (
    <>
      <Header />
      <Services />
      <Contact />
    </>
  );
}
