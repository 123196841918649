import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PlaylistXC = ({ open, onClose, playlistToEdit }) => {
    const [name, setPlaylistName] = useState('');
    const [host, setPlaylistHost] = useState('');
    const [userName, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [protectedChecked, setProtectedChecked] = useState(false);
    const [pin, setPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');

    useEffect(() => {
        if (playlistToEdit) {
            // If playlistToEdit is provided, set initial state with playlist data
            setPlaylistName(playlistToEdit.Name);
            setPlaylistHost(playlistToEdit.URL);
            setUsername(playlistToEdit.userName);
            setPassword(playlistToEdit.Password);
            setProtectedChecked(playlistToEdit.protectedChecked);
            setPin(playlistToEdit.Pin);
            setConfirmPin(playlistToEdit.confirmPin);
        } else {
            // Reset state if playlistToEdit is not provided
            setPlaylistName('');
            setPlaylistHost('');
            setUsername('');
            setPassword('');
            setProtectedChecked(false);
            setPin('');
            setConfirmPin('');
        }
    }, [playlistToEdit]);

    const handleClose = () => {
        onClose();
    };

    const handleProtectedChange = (event) => {
        setProtectedChecked(event.target.checked);
    };

    const handleSave = () => {
        const data = {
            Name: name,
            URL: host,
            userName: userName,
            Password: password,
            protectedChecked: protectedChecked,
            Pin: protectedChecked ? pin : '',
            confirmPin: protectedChecked ? confirmPin : '',
        };

        axios.post('http://localhost:5000/playlists', data)
            .then(response => {
                toast.success("PlaylistXC saved successfully:", response.data);
                handleClose();
            })
            .catch(error => {
                toast.error("Error saving PlaylistXC:", error);
            });
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{playlistToEdit ? 'Edit PlaylistXC' : 'Add PlaylistXC'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Playlist Name"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setPlaylistName(e.target.value)}
                />
                <TextField
                    label="Playlist Host"
                    fullWidth
                    margin="normal"
                    value={host}
                    onChange={(e) => setPlaylistHost(e.target.value)}
                />
                <TextField
                    label="Username"
                    fullWidth
                    margin="normal"
                    value={userName}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={protectedChecked} onChange={handleProtectedChange} />}
                    label="Protect this playlist"
                />
                <TextField
                    label="PIN"
                    type="password"
                    fullWidth
                    margin="normal"
                    disabled={!protectedChecked}
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                />
                <TextField
                    label="Confirm PIN"
                    type="password"
                    fullWidth
                    margin="normal"
                    disabled={!protectedChecked}
                    value={confirmPin}
                    onChange={(e) => setConfirmPin(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PlaylistXC;
