import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Checkbox, FormControlLabel } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Playlist = ({ open, onClose, playlistToEdit, updatePlaylistData }) => {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const navigate = useNavigate();
    const [protectedChecked, setProtectedChecked] = useState(false);
    const [pin, setPin] = useState('');
    const [confirmPin, setConfirmPin] = useState('');
    const [userData, setuserData] = useState([]);


    useEffect(() => {
        if (playlistToEdit) {
            // If playlistToEdit is provided, set initial state with playlist data
            setName(playlistToEdit.playlist_name);
            setUrl(playlistToEdit.playlist_url);
            setProtectedChecked(playlistToEdit.protectedChecked);
            setPin(playlistToEdit.Pin);
            setConfirmPin(playlistToEdit.confirmPin);
        } else {
            // Reset state if playlistToEdit is not provided
            setName('');
            setUrl('');
            setProtectedChecked(false);
            setPin('');
            setConfirmPin('');
        }
    }, [playlistToEdit]);

    const handleClose = () => {
        onClose();
    };

    const handleProtectedChange = (event) => {
        setProtectedChecked(event.target.checked);
    };
    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo');
        if (!userInfo) {
            //  navigate('/login'); // Redirect to login if user information is not found
        }
        setuserData(JSON.parse(userInfo));
    }, []);
    const handleSave = () => {
        const data = {
            playlist_name: name,
            playlist_url: url,
            playlist_type: protectedChecked,
            protectedChecked: protectedChecked,
            mac_address: userData.data.MacAddress,
            Pin: protectedChecked ? pin : '',
            confirmPin: protectedChecked ? confirmPin : '',
        };

        if (playlistToEdit) {
            axios.put(`http://localhost:5000/playlists/${playlistToEdit.id}`, data)
                .then(response => {
                    toast.success("Playlist updated successfully:", response.data);
                    handleClose();


                })
                .catch(error => {
                    toast.error("Error updating playlist:", error);
                });
        } else {
            axios.post('http://localhost:5000/playlists', data)
                .then(response => {
                    toast.success('Playlist saved successfully:', response.data);
                    handleClose();
                })
                .catch(error => {
                    toast.error('Error saving playlist:', error);
                });
        }
        updatePlaylistData();

    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{playlistToEdit ? 'Edit Playlist' : 'Add Playlist'}</DialogTitle>
            <DialogContent>
                <TextField
                    label="Playlist Name"
                    fullWidth
                    margin="normal"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    label="Playlist URL"
                    fullWidth
                    margin="normal"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                />
                <FormControlLabel
                    control={<Checkbox checked={protectedChecked} onChange={handleProtectedChange} />}
                    label="Protect this playlist"
                />
                <TextField
                    label="PIN"
                    type="password"
                    fullWidth
                    margin="normal"
                    disabled={!protectedChecked}
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                />
                <TextField
                    label="Confirm PIN"
                    type="password"
                    fullWidth
                    margin="normal"
                    disabled={!protectedChecked}
                    value={confirmPin}
                    onChange={(e) => setConfirmPin(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Playlist;
