import React from 'react';
import {createRoot} from 'react-dom/client';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import './style/index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <Router>
    <App />
  </Router>
);
reportWebVitals();
