import React, { useState, useEffect } from 'react';
import { Button, Table, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Playlist from './Playlist';
import PlaylistXC from './PlaylistXC';
import UserList from './UserList'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const ManagePlaylist = () => {
    const navigate = useNavigate();
    const [showTable, setShowTable] = useState(true);
    const [showPay, setshowPay] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [playlistData, setPlaylistData] = useState([]);
    const [openPlaylistDialog, setOpenPlaylistDialog] = useState(false);
    const [openPlaylistXcDialog, setOpenPlaylistXcDialog] = useState(false);
    const [playlistToEdit, setPlaylistToEdit] = useState(null);
    const [userData, setUserData] = useState([]);
    const [role, setRole] = useState('');

    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo');
        if (!userInfo) {
            navigate('/login');
        } else {
            setUserData(JSON.parse(userInfo));
        }
    }, []);

    useEffect(() => {
        if (userData && userData.data && userData.data.mac_address) {
            setRole(userData.data.admin);
            if (userData.data.admin === "admin") {
                setshowPay(false);
                setShowUser(true);
                setShowTable(false);
            }
            axios.get(`http://localhost:5000/playlists?macAddress=${userData.data.mac_address}`)
                .then(response => {
                    setPlaylistData(response.data);
                })
                .catch(error => {
                    toast.error("Error fetching playlist data:", error);
                });
        }
    }, [userData]);
    const handlePlaylistClick = () => {
        setShowTable(true);
        setshowPay(false);
    };

    const handleActivityClick = () => {
        setShowTable(false);
        setshowPay(true);
    };
    const handleActivityClick2 = () => {
        setShowTable(false);
        setshowPay(false);
        setShowUser(true);
    };
    const handleLogoutClick = () => {
        localStorage.removeItem('userInfo'); 
        navigate('/login');
    };
    const WhatsAppPaymentButton = () => {
        const whatsappNumber = '+970599507493';
        const url = `https://wa.me/${whatsappNumber}`;
        window.open(url, '_blank');
    }
    const handleAddPlaylistClick = () => {
        setOpenPlaylistDialog(true);
    };

    const handleAddXCPlaylistClick = () => {
        setOpenPlaylistXcDialog(true);
    };

    const handleEditClick = (row) => {
        setPlaylistToEdit(row);

        if (row.userName == undefined) {

            setOpenPlaylistDialog(true);
        } else if (row.userName !== '') {
            setOpenPlaylistXcDialog(true);
        } else {
            setOpenPlaylistDialog(true);
        }
    };

    const handleClosePlaylistDialog = () => {
        setOpenPlaylistDialog(false);
        setPlaylistToEdit(null);
    };
    const updatePlaylistData = () => {
        axios.get('http://localhost:5000/playlists')
            .then(response => {
                setPlaylistData(null);
                setPlaylistData(response.data);
            })
            .catch(error => {
                toast.error("Error fetching updated playlist data:", error);
            });
    };
    const handleClosePlaylistXcDialog = () => {
        setOpenPlaylistXcDialog(false);
    };
    const handleDeleteClick = async (rowId) => {
        try {
            const response = await axios.delete(`http://localhost:5000/playlists/${rowId}`, {
            });

            if (!response.ok) {
                toast.error("Delete request failed");
            }
            setPlaylistData(playlistData.filter((playlist) => playlist._id !== rowId));
            axios.get('http://localhost:5000/playlists')
                .then(response => {
                    setPlaylistData(response.data);
                })
                .catch(error => {
                    toast.error("Error fetching updated playlist data:", error);
                    
                });
             toast.success("Playlist deleted successfully!");
        } catch (error) {
             toast.error("Error deleting playlist. Please try again.");
        }
    };


    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10%', alignItems: 'center', width: '100%' }}>
            <Grid container alignItems="center" spacing={2} style={{ marginLeft: '20%', width: '10%' }}>
                {role !== 'admin' ? (
                    <><Grid item xs={8}>
                        <Button variant="contained" style={{ backgroundColor: 'green' }} onClick={handlePlaylistClick}>Playlist</Button>
                    </Grid><Grid item xs={8}>
                            <Button variant="contained" style={{ backgroundColor: 'green' }} onClick={handleActivityClick}>Activate</Button>
                        </Grid></>
                ) : (<Grid item xs={8}>
                    <Button variant="contained" style={{ backgroundColor: 'green' }} onClick={handleActivityClick2}>User    </Button>
                </Grid>)}

                <Grid item xs={8}>
                    <Button style={{ backgroundColor: 'red' }} variant="contained" onClick={handleLogoutClick}>Logout</Button>
                </Grid>
            </Grid>
            {showTable && (
                <TableContainer component={Paper} style={{ marginRight: '10%' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead style={{ marginLeft: '50%' }}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell> Mac Address: {userData.data?.mac_address}</TableCell>
                            </TableRow>
                            <TableRow>

                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>DeviceKey: {userData?.data?.device_key}</TableCell>
                            </TableRow>
                            <TableRow>

                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>Status: {userData?.data?.Status}</TableCell>
                            </TableRow>
                            <TableRow>

                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell> Expiration: {userData?.data?.expire_date}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow >
                                <Button variant="contained" style={{ backgroundColor: 'green', marginBottom: '2%' }} onClick={handleAddPlaylistClick}>Add Playlist</Button>
                                <Button style={{ marginLeft: '0%', backgroundColor: 'green', marginBottom: '2%' }} variant="contained" onClick={handleAddXCPlaylistClick}>Add PlaylistXC</Button>
                                <Playlist open={openPlaylistDialog} onClose={handleClosePlaylistDialog} playlistToEdit={playlistToEdit} updatePlaylistData={updatePlaylistData} />
                                <PlaylistXC open={openPlaylistXcDialog} onClose={handleClosePlaylistXcDialog} playlistToEdit={playlistToEdit} updatePlaylistData={updatePlaylistData} />
                            </TableRow>
                        </TableHead>
                        <TableHead style={{ backgroundColor: 'darkgray' }}>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">URL</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {playlistData?.map((row) => (
                                <TableRow key={row.playlist_name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {row.playlist_name}
                                    </TableCell>
                                    <TableCell align="right">{row.playlist_url}</TableCell>
                                    <TableCell align="right">
                                        <Button variant="contained" style={{ backgroundColor: 'green' }} onClick={() => handleEditClick(row)}>Edit</Button>
                                        <Button style={{ marginLeft: '2%', backgroundColor: 'red' }} variant="contained" onClick={() => handleDeleteClick(row._id)}>Delete</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {showPay && (
                <div style={{ width: '70%', marginRight: '7%', backgroundColor: '#fef' }}>
                    <p style={{ marginTop: '2%', marginLeft: '1%', marginBottom: '2%', marginRight: '2%', color: 'black' }}>
                        Disclaimer
                        We don't provide contents but pure media player. To use our player, you have to upload your own playlist. If you have no playlist, you can't use the player anymore so in this case no need to pay. Also we are not allowing activation for the users that don't have own playlist.

                        As each device has an unique Mac address, a payment is valid for a device only.
                    </p><hr></hr><p style={{ fontWeight: 'bold', marginLeft: '1%' }}>Price: 7.99 € (For lifetime) Contact on WhatsApp   <IconButton style={{ backgroundColor: 'green' }} onClick={WhatsAppPaymentButton}>
                        <WhatsAppIcon />
                    </IconButton></p>


                </div>

            )}
            {showUser && (
                <UserList></UserList>
            )

            }
        </Box>
    );
};

export default ManagePlaylist;
